<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "QuestionLayout"
}
</script>

<style scoped>
@import "~@/assets/scss/forms.scss";

</style>